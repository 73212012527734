import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Site from './layout/Site'
import Header from './layout/Header'
import Content from './layout/Content'
import Footer from './layout/Footer'
import Router from './layout/Router'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ children }) => (
	<Site>
		<ToastContainer />
		<Helmet
			title="3 Brothers Painting Co."
			meta={[
				{ name: 'description', content: '3 Brothers Painting Co.' },
				{ name: 'keywords', content: 'painting, 3 brothers' },
			]}
			script={[
				{ 'src': 'https://use.fontawesome.com/releases/v5.4.1/js/all.js' },
				{
					'src': "https://kit.fontawesome.com/2828f7885a.js",
					'integrity': "sha384-WAsFbnLEQcpCk8lM1UTWesAf5rGTCvb2Y+8LvyjAAcxK1c3s5c0L+SYOgxvc6PWG",
					'crossorigin': "anonymous"
				},
				// { 'src': 'https://cdn.rawgit.com/michalsnik/aos/2.1.1/dist/aos.js' }, included in npm package and in 
			]}
			link={[
				{ 'rel': 'stylesheet', 'href': 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css' },
				{ 'rel': 'stylesheet', 'href': 'https://cdn.rawgit.com/michalsnik/aos/2.1.1/dist/aos.css' }
			]}
		/>
		<Header />
		<Content>
			<Router />
		</Content>
		<Footer />
	</Site>
)

Layout.propTypes = {
	children: PropTypes.func
}

export default Layout