import React from 'react';
import jqueryObect from 'jquery';
import AOS from 'aos';
import { Lightbox } from "react-modal-image";
const PHONE_NUMBER = '716-200-7245'
require('./styles/card.css');

export default class Home extends React.Component {

	constructor() {
		super();
		this.state = {
			isLoading: true,
			isImageModal: false,
			imageExpansion: null,
			imageExpansionDescription: null
		}
		this.contactRef = React.createRef();
	}

	scrollToContactRef = () => {
		this.contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	componentDidMount() {
		document.addEventListener("DOMContentLoaded", this.handleScroll);
		jqueryObect(".preloader-wrapper").fadeOut();
		jqueryObect("body").removeClass("preloader-site");
		jqueryObect("body").addClass("preloader-site");

		// When the user scrolls down 20px from the top of the document, show the scroll up button
		window.onscroll = () => {
			this.scrollFunction();
		};

		// Smooth Anchor Scrolling
		jqueryObect(document).on("click", 'a[href^="#"]', function (event) {
			event.preventDefault();

			// TODO: this is fucked up but whatever
			const reference = jqueryObect.attr(this, "href");
			let offsetHeight = 0;
			if (reference.includes('about')) {
				offsetHeight = 115;
			} else if (reference.includes('contact')) {
				offsetHeight = 130;
			} else if (reference.includes('work')) {
				offsetHeight = 135;
			} else if (reference.includes('services')) {
				offsetHeight = 135;
			}
			jqueryObect("html, body").animate({ scrollTop: jqueryObect(reference).offset().top - offsetHeight }, 500);
		});
		AOS.init({ easing: "ease-out", duration: 800 });
	}

	componentWillUnmount() {
		window.document.removeEventListener('DOMContentLoaded', this.handleScroll);
	}

	scrollFunction = () => {
		if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			document.getElementById("toTop").style.display = "block";
		} else {
			document.getElementById("toTop").style.display = "none";
		}
	}

	handleScroll = () => {
		// Get all "navbar-burger" elements
		let navbarBurgers = Array.prototype.slice.call(
			document.querySelectorAll(".navbar-burger"), 0);
		// Check if there are any navbar burgers
		if (navbarBurgers.length > 0) {
			// Add a click event on each of them
			navbarBurgers.forEach((el) => {
				el.addEventListener("click", () => {
					// Get the target from the "data-target" attribute
					let target = el.dataset.target;
					let $target = document.getElementById(target);
					// Toggle the class on both the "navbar-burger" and the "navbar-menu"
					el.classList.toggle("is-active");
					$target.classList.toggle("is-active");
				});
			});
		}
	}

	/**
	 * TODO: break this into a component
	 */
	renderCardContent = (icon = "fa-paint-brush", title = "TITLE", content = "CONTENT",
		backTitle = "BACK TITLE", backContent = "BACK CONTENT", backFontSize = '1.0em') => {
		return (
			<div class='container'>
				<div class="flip-container" ontouchstart="this.classList.toggle('hover');">
					<div class="flipper">
						<div class="front">
							<div style={{ margin: '20px' }}>
								<div class=" has-text-centered">
									<span class="icon"
										style={{
											background: 'black',
											borderRadius: '30px',
											width: '62px',
											height: '62px'
										}}>
										<i class={`fad ${icon} fa-2x`} style={{ color: '#75C045' }}></i>
									</span>
									<h1 style={{ fontSize: 20 }}>{title}</h1>
									{/* <hr /> this is a line breakpoint*/}
									<h2>
										{content}
									</h2>
								</div>
							</div>
						</div>
						<div class="back">
							<div style={{ margin: '20px' }}>
								<div class=" has-text-centered">
									{/* <hr /> */}
									<div style={{ marginTop: backTitle == null ? '0%' : '10%' }}></div>
									{backTitle != null && <h1 style={{ fontSize: 20 }}>{backTitle}</h1>}
									<h2 style={{ fontSize: backFontSize }}>
										{backContent}
									</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderTopContent = () => {
		return (
			<div id="top-content" >
				<div class="container">
					<div class="column is-12 about">
						<h1 class="title has-text-centered section-title">
							<a className="is-default-txt button is-large button-border-color" href={`tel:1-${PHONE_NUMBER}`}>
								CALL NOW!
							</a>
							<br />
							<div style={{ marginBottom: '20px' }} />
							<a className="is-default-txt button is-large button-border-color is-flex-mobile"
								onClick={() => { this.scrollToContactRef('contact') }}>
								Schedule Free Estimate
							</a>
						</h1>
					</div>
				</div>
			</div>
		)
	}

	renderContact = () => {
		return (
			<div class="section-light contact" id="contact" ref={this.contactRef}>
				<div class="container">
					<div class="columns is-multiline"
						data-aos="fade-in-up"
						data-aos-easing="linear" >
						<div class="column is-12 about">
							<h1 class="title has-text-centered section-title">
								Contact Us
						</h1>
						</div>
						<div class="column is-8 is-offset-2">
							<form
								// TODO: what 3brothers paint email do they want it to go to
								action="https://formsubmit.co/3BPWNY@GMAIL.COM"
								method="POST"
								encType="multipart/form-data">
								<input type="hidden" name="_next" value="https://3brotherspaint.com" />
								<div class="field">
									<label class="label">Name</label>
									<div class="control has-icons-left">
										<input
											class="input"
											type="text"
											placeholder="Ex. Jane Smith"
											name="name" />
										<span class="icon is-small is-left">
											<i class="fas fa-user"></i>
										</span>
									</div>
								</div>
								<div class="field">
									<label class="label">Email</label>
									<div class="control has-icons-left">
										<input
											class="input"
											type="name"
											placeholder="Ex. hello@arctheme.com"
											name="email" />
										<span class="icon is-small is-left">
											<i class="fas fa-envelope"></i>
										</span>
									</div>
								</div>
								<div class="field">
									<label class="label">Message</label>
									<div class="control">
										<textarea class="textarea"
											placeholder="Textarea"
											name="message" />
									</div>
								</div>
								<div class="field">
									<div class="control">
										<button class="button submit-button" type="submit" value="Send">
											Submit&nbsp;&nbsp;
									  <i class="fas fa-paper-plane"></i>
										</button>
									</div>
								</div>
							</form>
							<div class="column is-12 about">
								<h1 class="title has-text-centered section-title is-size-4">
									OR <br />
									<div style={{ marginBottom: '20px' }} />
									<a className="is-default-txt disable-highlight" href={`tel:1-${PHONE_NUMBER}`}>
										Call Us
										<span className='has-text-justified' style={{ paddingLeft: 10 }}>{PHONE_NUMBER}</span>
									</a>
								</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderServices = () => {
		return (
			<div class="section-color services" id="services">
				<div class="container">
					<div class="columns is-multiline">
						<div
							class="column is-12 about"
							data-aos="fade-in"
							data-aos-easing="linear">
							<h1 class="title has-text-centered section-title"><u>Services</u></h1>
							{/* <h2 class="subtitle has-text-centered">
								Here's some of the Services that we provide:
						  </h2> */}
							{/* <br /> */}
						</div>
						<div class="columns is-12">
							<div
								// class="column is-4 has-text-centered"
								class="column is-6"
								data-aos="fade-in"
								data-aos-easing="linear"
							>
								{/* <i class="fad fa-meteor fa-3x"></i> */}
								<h1 class="title has-text-centered section-title">Interior Painting</h1>
								{/* <hr /> */}
								<h2>
									Whether you are painting one room or planning a complete facelift of your entire interior space, our interior
									painters can help you obtain the look you desire. It can be difficult to visualize how different finishes,
									colors, and lighting can affect the appearance. 3 Brothers Paint Co. can provide you with the expertise and
									professionalism you need to complete your project.
							</h2>
							</div>
							<div
								class="column is-6 has-text-centered"
								data-aos="fade-in"
								data-aos-easing="linear"
							>
								{/* <i class="fas fa-paint-brush fa-3x"></i> */}
								<h1 class="title has-text-centered section-title">Exterior Painting</h1>
								{/* <hr /> */}
								<h2>
									3 Brothers Paint Co. is a full-service exterior painting contractor. Residential and commercial clients
									are delighted that they chose 3PB for its extraordinary standards in prep work, priming, and perfect
									finish coat. We have up-to-date product knowledge to assure you of a flawless paint job that will be
									sure to please, endure, and add real value to your home or business. Your home may be contemporary,
									historic, or heritage; your project may be one side of a house or building, or the entire exterior.
									Whatever your requirement, we offer a highly personalized and comprehensive painting and decorating service
									& customized solutions to meet your specific needs.
							</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderWorkContent = () => {

		const imageGenerator = (imageUrl, columnSize = 3) => {
			return (<div class={`column is-${columnSize.toString()}`}>
				<a onClick={() =>
					this.setState({
						isImageModal: true,
						imageExpansion: require(imageUrl),
						imageExpansionDescription: ""
					})
				}>
					<figure
						class="image is-2by1 work-item"
						style={{ backgroundImage: `url(${require(imageUrl)})` }}
					></figure>
				</a>
			</div>)
		}

		return (
			<div class="section-dark my-work" id="work" >
				<div class="container">
					<div
						class="columns is-multiline"
						data-aos="fade-in"
						data-aos-easing="linear">
						<div class="column is-12">
							<h1 class="title has-text-centered section-title">Our Work</h1>
						</div>
						{/* TODO IMPLEMENT THIS */}
						{/* {imageGenerator('../images/work/1.jpg')}
						{imageGenerator('../images/work/2.jpg')}
						{imageGenerator('../images/work/3.jpg')}
						{imageGenerator('../images/work/4.jpg')}
						{imageGenerator('../images/work/5.jpg')}
						{imageGenerator('../images/work/6.jpg')}
						{imageGenerator('../images/work/7.jpg')}
						{imageGenerator('../images/work/8.jpg')} */}
						<div class="column is-3">
							<a>
								<figure
									class="image is-2by1 work-item"
									style={{ backgroundImage: `url(${require('../images/work/1.jpg')})` }}
									onClick={() =>
										this.setState({
											isImageModal: true,
											imageExpansion: require('../images/work/1.jpg'),
											imageExpansionDescription: ""
										})
									}
								></figure>
							</a>
						</div>
						<div class="column is-3">
							<a>
								<figure
									class="image is-2by1 work-item"
									style={{ backgroundImage: `url(${require('../images/work/2.jpg')})` }}
									onClick={() =>
										this.setState({
											isImageModal: true,
											imageExpansion: require('../images/work/2.jpg'),
											imageExpansionDescription: ""
										})
									}
								></figure>
							</a>
						</div>
						<div class="column is-3">
							<a>
								<figure
									class="image is-2by1 work-item"
									style={{ backgroundImage: `url(${require('../images/work/3.jpg')})` }}
									onClick={() =>
										this.setState({
											isImageModal: true,
											imageExpansion: require('../images/work/3.jpg'),
											imageExpansionDescription: ""
										})
									}
								></figure>
							</a>
						</div>
						<div class="column is-3">
							<a>
								<figure
									class="image is-2by1 work-item"
									style={{ backgroundImage: `url(${require('../images/work/4.jpg')})` }}
									onClick={() =>
										this.setState({
											isImageModal: true,
											imageExpansion: require('../images/work/4.jpg'),
											imageExpansionDescription: ""
										})
									}
								></figure>
							</a>
						</div>
						<div class="column is-3">
							<a>
								<figure
									class="image is-2by1 work-item"
									style={{ backgroundImage: `url(${require('../images/work/5.jpg')})` }}
									onClick={() =>
										this.setState({
											isImageModal: true,
											imageExpansion: require('../images/work/5.jpg'),
											imageExpansionDescription: ""
										})
									}
								></figure>
							</a>
						</div>
						<div class="column is-3">
							<a>
								<figure
									class="image is-2by1 work-item"
									style={{ backgroundImage: `url(${require('../images/work/6.jpg')})` }}
									onClick={() =>
										this.setState({
											isImageModal: true,
											imageExpansion: require('../images/work/6.jpg'),
											imageExpansionDescription: ""
										})
									}
								></figure>
							</a>
						</div>
						<div class="column is-3">
							<a>
								<figure
									class="image is-2by1 work-item"
									style={{ backgroundImage: `url(${require('../images/work/7.jpg')})` }}
									onClick={() =>
										this.setState({
											isImageModal: true,
											imageExpansion: require('../images/work/7.jpg'),
											imageExpansionDescription: ""
										})
									}
								></figure>
							</a>
						</div>
						<div class="column is-3">
							<a>
								<figure
									class="image is-2by1 work-item"
									style={{ backgroundImage: `url(${require('../images/work/8.jpg')})` }}
									onClick={() =>
										this.setState({
											isImageModal: true,
											imageExpansion: require('../images/work/8.jpg'),
											imageExpansionDescription: ""
										})
									}
								></figure>
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderAbout = () => {
		return (
			<div class="section-light about" id="about">
				<div class="container">
					<div class="column is-12 about">
						<h1 class="title has-text-centered section-title">About</h1>
					</div>
					<div class="columns is-multiline">
						{/* <div
						class="column is-6 has-vertically-aligned-content"
						data-aos="fade-right"
					> */}
						{/* <p class="is-larger"> */}
						<p>
							{/*&emsp;&emsp;*/}
							3 Brothers Paint Company was founded in Buffalo, New York in 2010.
							Family owned and operated, 3BP was created by 3 ambitious brothers looking to beautify Buffalo,
							one brush stroke at a time. By offering the highest quality paint techniques and exceptional
							craftsmanship, 3BP has established itself as the most reliable and trustworthy paint contractors in WNY.
						</p>
						<br />
						<p>
							Of all the improvements you can make to beautify and add value to your home, nothing is more cost-effective or makes a bigger impact than a fresh coat of paint.

							Whether you're looking to put your house on the market or you'd simply like a fresh new look, our experienced painters will make sure the job gets done quickly, professionally and with minimal disruption to your daily routine.

							We offer a full menu of painting services for your home or apartment, including:

							Public areas of apartment buildings – hallways, lobby, etc.
							Custom interior painting, wallpaper hanging and removal. Skim coating and plaster work.
							Environmental friendly and NO-VOC paint. Woodwork Sanding, painting and special finishes.
						  </p>
						<br />
						<div class="is-divider" />
					</div>
				</div>
			</div>
		)
	}

	renderCardSection = (marginTop = '30px', marginBottom = '30px') => {
		return (
			<div class="container" style={{ marginTop: marginTop, marginBottom: marginBottom }}>
				<div class="columns">
					<div class="column">
						{this.renderCardContent("fa-home", "Interior Painting",
							"We have the proper tools to help you in your local area.",
							null, "3 Brothers Paint interior house painting services provide a seamless," +
							" efficient, and meticulous interior painting job that will make a world of difference" +
						"to your home’s overall look and impact.")}
					</div>
					<div class="column">
						{this.renderCardContent("fa-paint-brush", "Exterior Painting",
							"We can help you with custom work if you contact us with details.",
							null, "Exterior house painting is the most effective way to boost the curb appeal of your house." +
							"With our expert touch, five star prep methods and meticulous cleanup skills, we can assure that your house" +
						"will brighten up the entire neighborhood.")}
					</div>
					<div class="column">
						{this.renderCardContent("fa-city", "Commercial Painting",
							"Own a business or commercial real estate? We can help.",
							null, "Our line of commercial painting services includes interior painting, exterior painting," +
							"power washing, color consulting and more. If you have a commercial property in need of professional" +
						"painting services, contact us today. We will provide a clear and timely estimate and get the job done quickly.", '0.9em')}
					</div>
				</div>
			</div>
		)
	}

	render() {
		return (
			<div>
				{/* <body> */}
				{/* <!-- Begin Preloader --> */}
				<div class="preloader-wrapper">
					<div class="preloader">
						{/* <img src="../images/preloader.gif" alt="" /> */}
						<img src={require('../images/preloader.gif')} alt="" />
					</div>
				</div>
				{/* <!-- End Preloader--> */}

				{/* <!-- Begin Main Content --> */}
				<div class="main-content" id="top-id">
					{this.state.isImageModal && <Lightbox large={this.state.imageExpansion}
						alt={this.state.imageExpansionDescription}
						onClose={() => this.setState({ isImageModal: false })}
					/>}
					{this.renderTopContent()}
					{this.renderCardSection('0px')}
					{this.renderServices()}
					{this.renderWorkContent()}
					{this.renderAbout()}
					{this.renderContact()}
				</div>
				{/* <!-- End Main Content --> */}
				{/* </body> */}
			</div >
		)
	}
}