import React from 'react';
import Layout from './app/Layout';
import { loadReCaptcha } from 'react-recaptcha-v3';

export default class App extends React.Component {
	componentDidMount() {
		loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY);
	}
	render() {
		return (<Layout />);
	}
}