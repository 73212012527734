import React from 'react';

const Footer = () => (
	<footer className="footer footer-custom">
		<div className="container is-default-txt">
			<div className="content has-text-centered">
				<p> Powered By <strong className="is-default-txt">3 Brothers Painting Co.</strong> <br />
					Copyright {new Date().getFullYear()}
					<div class="social-media">
						<a href="https://facebook.com" target="_blank" class="button is-text is-large">
							<i class="fab fa-facebook-square" aria-hidden="true"></i>
						</a>
					&nbsp;
					<a href="https://instagram.com" target="_blank" class="button is-text is-large">
							<i class="fab fa-instagram" aria-hidden="true"></i>
						</a>
					&nbsp;
					<a href="https://twitter.com" target="_blank" class="button is-text is-large">
							<i class="fab fa-twitter" aria-hidden="true"></i>
						</a>
					</div>
				</p>
			</div>
		</div>
	</footer>
)

export default Footer