import React from 'react';
const PHONE_NUMBER = '716-200-7245'
const EMAIL = '3BPWNY@GMAIL.COM';

export default class Header extends React.Component {

	state = {
		activeTab: '',
		isActive: false,
		isDropdownActive: false
	}

	onTabClick = (tab) => {
		this.setState({ activeTab: tab });
	}

	isTabActive = (tab) => {
		if (tab === this.state.activeTab) {
			return 'is-active'
		}
		return '';
	}

	scrollToTop = () => {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	}

	render() {
		return (
			<div>
				{/* <!-- Begin Scroll Up Button --> */}
				<button class="toTopButton" id="toTop" title="Go to top" onClick={() => {
					this.scrollToTop();
					this.onTabClick(null);
				}}>
					<i class="fas fa-angle-up"></i>
				</button>
				{/* <!-- End Scroll Up Button --> */}
				{/* <!-- Begin Header --> */}
				<div class="header-wrapper" id="home">
					{/* <!-- Begin Hero --> */}
					<section class="hero is-small">
						{/* <!-- Hero HEAD --> */}
						<div class="hero-head" style={{ background: 'white' }}>
							<header class="navbar">
								<div class="container">
									<div class="navbar-brand">
										<a className="navbar-item" href="/" >
											<img src={require('../../images/new-logo-image.png')} style={{ maxHeight: "none" }} width="300" height="300" />
										</a>
										<span class="navbar-burger burger is-burger-style" data-target="navbarMenuHeroC">
											<span className='hamburger-line'></span>
											<span className='hamburger-line'></span>
											<span className='hamburger-line'></span>
										</span>
									</div>
									<div id="navbarMenuHeroC" class="navbar-menu is-white-background">
										<div class="navbar-end">
											<a class="navbar-item is-active is-header-text disable-highlight"
												style={{ fontSize: '20px' }} href={`mailto:${EMAIL}`}>
												<span className="icon">
													<i className="fas fa-envelope"></i>
												</span><span style={{ fontSize: '20px', paddingLeft: 5 }}>{EMAIL}</span>
											</a>
											<a className="navbar-item is-default-txt is-header-text disable-highlight" href={`tel:1-${PHONE_NUMBER}`}>
												<span className="icon">
													<i className="fas fa-phone"></i>
												</span><span style={{ fontSize: '20px', paddingLeft: 5 }}>{PHONE_NUMBER}</span>
											</a>
											<div className="is-header-hr" />
											{/* THESE ARE OUR TABS */}
											<nav class="navbar is-transparent is-hidden-desktop is-white-background">
												<div class="navbar-item">
													<a class="navbar-item" href="#services">
														Services
												</a>
												</div>
												<div class="navbar-item">
													<a class="navbar-item" href="#work">
														Our Work
												</a>
												</div>
												<div class="navbar-item">
													<a class="navbar-item" href="#about">
														About
												</a>
												</div>
												<div class="navbar-item">
													<a class="navbar-item" href="#contact">
														Contact
												</a>
												</div>
											</nav>
										</div>
									</div>
								</div>
							</header>
						</div>

						<div class="hero-foot">
							<div class="hero-foot--wrapper">
								<div class="columns">
									<div class="column is-12 hero-menu-desktop has-text-centered">
										<ul>
											<li class={`${this.isTabActive('services')}`} onClick={() => { this.onTabClick('services') }}>
												<a href="#services">Services</a>
											</li>
											<li class={`${this.isTabActive('work')}`} onClick={() => { this.onTabClick('work') }}>
												<a href="#work">Our Work</a>
											</li>
											<li class={`${this.isTabActive('about')}`} onClick={() => { this.onTabClick('about') }}>
												<a href="#about">About</a>
											</li>
											<li class={`${this.isTabActive('contact')}`} onClick={() => { this.onTabClick('contact') }}>
												<a href="#contact">Contact</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						{/* <!-- End Hero Menu --> */}
					</section>
					{/* <!-- End Hero --> */}
				</div>
				{/* <!-- End Header --> */}
			</div >
		)
	}
}